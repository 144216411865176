<template>
  <container-list-extend title="Contrat" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div
        class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1"
      >
        <div style>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-success"
            fill="currentColor"
            v-if="habilitation.habilitation_active"
            v-tooltip="'Habilitation active'"
          ></BaseIcon>
          <BaseIcon
            name="circle"
            width="10"
            height="10"
            class="text-danger"
            fill="currentColor"
            v-if="!habilitation.habilitation_active"
            v-tooltip="'Habilitation inactive'"
          ></BaseIcon>
        </div>
        <h6 class="m-0 ml-3" style="line-height:1rem;">
          <description-from-list
            :value="habilitation.habilitation_libelle_id"
            :data="data.habilitationLibelles"
            text="Nouvelle habilitation"
          ></description-from-list>
        </h6>
      </div>
      <div
        class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center"
      >
        <small>
          <div v-if="habilitation.habilitation_date_validite">
            validité :
            {{
              $moment(habilitation.habilitation_date_validite).format(
                "DD/MM/YYYY"
              )
            }}
          </div>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        :loading="loading"
        deleteBtn
        @click="updateHabilitation(habilitation)"
        @delete="deleteHabilitation(habilitation)"
      >
        <base-form-row row>
          <base-select
            inputText="Habilitation"
            v-model.number="habilitation.habilitation_libelle_id"
            :options="data.habilitationLibelles"
          ></base-select>
          <base-select
            inputText="Domaine"
            v-model.number="habilitation.habilitation_domaine_id"
            :options="data.habilitationDomaines"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-inputDatePicker
            v-model="habilitation.habilitation_date"
            inputText="Date de début"
          ></base-inputDatePicker>
          <base-inputDatePicker
            v-model="habilitation.habilitation_date_validite"
            inputText="Date de validité"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="habilitation.habilitation_commentaire"
            text="Commentaire"
          ></base-input-area>
        </base-form-row>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import DescriptionFromList from "@/components/bases/DescriptionFromList";
import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";

export default {
  name: "FormationsItem",
  components: {
    BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseSelect,
    BaseInputDatePicker,
    BaseInputArea,
    DescriptionFromList
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null
    };
  },
  props: ["habilitation"],
  computed: {
    ...mapGetters({
      data: "collaborateurs/data"
    })
  },
  methods: {
    ...mapActions({
      _updateHabilitation: "collaborateurs/updateHabilitation",
      _deleteHabilitation: "collaborateurs/deleteHabilitation"
    }),
    updateHabilitation: function(habilitation) {
      this.feedback = {};
      this.loading = true;
      this._updateHabilitation(habilitation)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteHabilitation: function(habilitation) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteHabilitation(habilitation)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>
