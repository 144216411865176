<template>
  <div
    class="rounded p-3 mr-md-3 shadow-sm"
    v-background-3
    style="min-width:300px;"
  >
    <!-- LISTE DES FORMATIONS -->
    <div class="mr-3" v-if="formations && formations.length > 0">
      <strong>Liste des formations</strong>
      <small>
        <div v-for="frm in formations" :key="frm.id">
          <div class="d-flex">
            <div
              v-if="frm.formation_date_validite"
              class="mr-1"
              style="width:74px;"
              :class="{
                'text-success':
                  $moment(frm.formation_date_validite) >= $moment(),
                'text-danger': $moment(frm.formation_date_validite) < $moment()
              }"
            >
              {{ $moment(frm.formation_date_validite).format("DD/MM/YYYY") }}
            </div>
            <div class="mr-1 text-muted" v-else style="width:74px;">NA</div>
            <description-from-list
              :value="frm.formation_libelle_id"
              :data="data.formationLibelles"
              text="Nouvelle formation"
              class="text-truncate"
            ></description-from-list>
          </div>
        </div>
      </small>
    </div>
    <div v-else>Aucune formation</div>
    <!-- LISTE DES VMS -->
    <div class="mt-3" v-if="vms && vms.length > 0">
      <strong>Liste des visites médicales</strong>
      <small>
        <div v-for="vm in vms" :key="vm.id" class="d-flex">
          <div
            class="mr-1"
            style="width:74px;"
            v-if="vm.vm_date_validite"
            :class="{
              'text-success': $moment(vm.vm_date_validite) >= $moment(),
              'text-danger': $moment(vm.vm_date_validite) < $moment()
            }"
          >
            {{ $moment(vm.vm_date_validite).format("DD/MM/YYYY") }}
          </div>
          <div class="mr-1 text-muted" v-else style="width:74px;">NA</div>
          <description-fromList
            :value="vm.vm_type_id"
            :data="data.vmTypes"
            text="Nouvelle visite médicale"
          ></description-fromList>
        </div>
      </small>
    </div>
    <div v-else>Aucune visite médicale</div>
  </div>
</template>
<script>
import DescriptionFromList from "@/components/bases/DescriptionFromList";

export default {
  components: {
    DescriptionFromList
  },
  data() {
    return {};
  },
  props: ["formations", "vms", "data"],
  methods: {},
  mounted() {},
  computed: {}
};
</script>
<style lang="css"></style>
