var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"background-3",rawName:"v-background-3"}],staticClass:"rounded p-3 mr-md-3 shadow-sm",staticStyle:{"min-width":"300px"}},[(_vm.formations && _vm.formations.length > 0)?_c('div',{staticClass:"mr-3"},[_c('strong',[_vm._v("Liste des formations")]),_c('small',_vm._l((_vm.formations),function(frm){return _c('div',{key:frm.id},[_c('div',{staticClass:"d-flex"},[(frm.formation_date_validite)?_c('div',{staticClass:"mr-1",class:{
              'text-success':
                _vm.$moment(frm.formation_date_validite) >= _vm.$moment(),
              'text-danger': _vm.$moment(frm.formation_date_validite) < _vm.$moment()
            },staticStyle:{"width":"74px"}},[_vm._v(" "+_vm._s(_vm.$moment(frm.formation_date_validite).format("DD/MM/YYYY"))+" ")]):_c('div',{staticClass:"mr-1 text-muted",staticStyle:{"width":"74px"}},[_vm._v("NA")]),_c('description-from-list',{staticClass:"text-truncate",attrs:{"value":frm.formation_libelle_id,"data":_vm.data.formationLibelles,"text":"Nouvelle formation"}})],1)])}),0)]):_c('div',[_vm._v("Aucune formation")]),(_vm.vms && _vm.vms.length > 0)?_c('div',{staticClass:"mt-3"},[_c('strong',[_vm._v("Liste des visites médicales")]),_c('small',_vm._l((_vm.vms),function(vm){return _c('div',{key:vm.id,staticClass:"d-flex"},[(vm.vm_date_validite)?_c('div',{staticClass:"mr-1",class:{
            'text-success': _vm.$moment(vm.vm_date_validite) >= _vm.$moment(),
            'text-danger': _vm.$moment(vm.vm_date_validite) < _vm.$moment()
          },staticStyle:{"width":"74px"}},[_vm._v(" "+_vm._s(_vm.$moment(vm.vm_date_validite).format("DD/MM/YYYY"))+" ")]):_c('div',{staticClass:"mr-1 text-muted",staticStyle:{"width":"74px"}},[_vm._v("NA")]),_c('description-fromList',{attrs:{"value":vm.vm_type_id,"data":_vm.data.vmTypes,"text":"Nouvelle visite médicale"}})],1)}),0)]):_c('div',[_vm._v("Aucune visite médicale")])])}
var staticRenderFns = []

export { render, staticRenderFns }